exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-asphalt-jsx": () => import("./../../../src/pages/asphalt.jsx" /* webpackChunkName: "component---src-pages-asphalt-jsx" */),
  "component---src-pages-concrete-jsx": () => import("./../../../src/pages/concrete.jsx" /* webpackChunkName: "component---src-pages-concrete-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-interlockingbrick-jsx": () => import("./../../../src/pages/interlockingbrick.jsx" /* webpackChunkName: "component---src-pages-interlockingbrick-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../../../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

